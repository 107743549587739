CKEDITOR.config.language = 'ca';
CKEDITOR.config.baseHref = '/vendor/ckeditor/ckeditor/';

//CKEDITOR.config.disableNativeSpellChecker = false;

//CKEDITOR.config.filebrowserImageBrowseUrl = '/admin/js/ck/elfinder.php?mode=images';
//CKEDITOR.config.filebrowserBrowseUrl = '/admin/js/ck/elfinder.php?mode=files';

//CKEDITOR.config.webmapBrowseUrl = '/admin/mapaweb.php?ckeditor';

//configuraciÃ³ pasteCustom
CKEDITOR.config.pasteCustomNumberedHeadingToList = true;

//desactivar filtre
CKEDITOR.config.allowedContent = {
  $1: {
    // Use the ability to specify elements as an object.
    elements: CKEDITOR.dtd,
    attributes: true,
    styles: true,
    classes: true
  }
};
CKEDITOR.config.disallowedContent = 'img[width,height,style,align]; table[border,cell*,width,height,style,align]';

//desactivar redimensionat imatges
CKEDITOR.config.disableObjectResizing = true;


CKEDITOR.on( 'dialogDefinition', function( ev ) {
  // Take the dialog name and its definition from the event data.
  var dialogName = ev.data.name;
  var dialogDefinition = ev.data.definition;

  if ( dialogName == 'link' ) {
    //eliminar pestanya
    dialogDefinition.removeContents( 'target' );
    dialogDefinition.removeContents( 'advanced' );

  }
  if ( dialogName == 'image' ) {
    //eliminar pestanya
    dialogDefinition.removeContents( 'Link' );

    //eliminar camps
    var infoTab = dialogDefinition.getContents( 'info' );
    infoTab.remove( 'txtBorder' );
    infoTab.remove( 'txtHSpace' );
    infoTab.remove( 'txtVSpace' );
    infoTab.remove( 'cmbAlign' );
    infoTab.remove( 'txtWidth' );
    infoTab.remove( 'txtHeight' );
    infoTab.remove( 'ratioLock' );
    infoTab.remove( 'txtHeight' );
  }

  if ( dialogName == 'image2' ) {
    //eliminar camps
    var infoTab = dialogDefinition.getContents( 'info' );
    //infoTab.remove( 'width' );
    //infoTab.remove( 'height' );
    //infoTab.remove( 'lock' );
    infoTab.remove( 'alignment' );
  }

  if ( dialogName == 'table' || dialogName == 'tableProperties' ) {
    var infoTab = dialogDefinition.getContents( 'info' );
    infoTab.remove( 'txtBorder' );
    infoTab.remove( 'txtHSpace' );
    infoTab.remove( 'txtVSpace' );
    infoTab.remove( 'cmbAlign' );
    infoTab.remove( 'txtWidth' );
    infoTab.remove( 'cmbWidthType' );
    infoTab.remove( 'txtHeight' );
    infoTab.remove( 'htmlHeightType' );
    infoTab.remove( 'txtCellSpace' );
    infoTab.remove( 'txtCellPad' );
  }
});

//plugins per composer
CKEDITOR.plugins.addExternal('pastetext','/vendor/ckeditor/pastetext/', 'plugin.js'); //botÃ³ d'enganchar sense format
//CKEDITOR.plugins.addExternal('autocorrect','/vendor/danyaPostfactum/ckeditor-autocorrect-plugin/autocorrect/', 'plugin.js'); // millores tipografiques


CKEDITOR.config.defaultLanguage = 'ca';
CKEDITOR.config.entities_latin = false;

CKEDITOR.config.resize_enabled = true;
/*CKEDITOR.config.resize_maxWidth = '775px';
CKEDITOR.config.width ='775px';*/
CKEDITOR.config.height='200px';
CKEDITOR.config.autoGrow_minHeight = 200;
CKEDITOR.config.autoGrow_maxHeight = 500;

CKEDITOR.config.embed_provider = '//iframe.ly/api/oembed?url={url}&callback={callback}&api_key=b6c09e6964eb9ff032435f';

CKEDITOR.config.toolbar_Full = [
  ['Cut','Copy','Paste','PasteText','-','Undo','Redo'],
  ['SelectAll','Find','Replace'],
  ['Bold','Italic','-','RemoveFormat'],
  ['NumberedList','BulletedList','-','Outdent','Indent',/*'Blockquote', 'CreateDiv'*/],
  ['Link','Unlink','Anchor'],
  ['Image', 'Embed','Table','SpecialChar','HorizontalRule'/*,'Pujar'*//*,'Webfonts'*/],
  ['Format','Maximize','ShowBlocks'/*,'WidgetTemplateMenu'*/],
  ['Source']
];
CKEDITOR.config.toolbar_Medium = [
  ['Cut','Copy','Paste','-','Undo','Redo'],
  ['Bold','Italic','-','Link','Unlink','-','NumberedList','BulletedList','-','Outdent','Indent','HorizontalRule','Format'],
  ['Source'],
];
CKEDITOR.config.toolbar_Basic = [
  ['Bold','Italic', '-', 'BulletedList'],
];

CKEDITOR.config.removePlugins = 'liststyle';

//CKEDITOR.config.extraPlugins = 'showblocks,selectall,find,autogrow,pastetext,horizontalrule,lineutils,widget,image2,autocorrect,embed,autoembed';
CKEDITOR.config.extraPlugins = 'showblocks,selectall,find,autogrow,pastetext,horizontalrule,lineutils,widget,image2,embed,autoembed';


function addEditor(elem, type) {
  $(elem).ckeditor({
    toolbar: type,
    contentsCss: [
      '/admin/css/editor.css'
    ],
    format_tags: 'p;h1;h2;h3;h4'
  });
}

$(document).ready(function() {

  $('textarea.editorAll, .editorAll textarea').each(function(index){
    console.log('a');
    addEditor(this, 'Full');
  });

  $('textarea.editorMedium, .editorMedium textarea').each(function(index){
    addEditor(this, 'Medium');
  });
  $('textarea.editorBasic, .editorBasic textarea').each(function(index){
    addEditor(this, 'Basic');
  });
});
